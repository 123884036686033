<template>
	<main
		class="site-content site-content--center page"
		id="wrapper"
		v-if="challenge !== undefined"
	>
		<div class="container">
			<div
				class="page-heading page-heading--default text-small text-center w-100"
			>
				<div class="page-heading__subtitle h5">
					<span class="color-primary"
						>{{
							new Date(challenge.createdOn).toDateString()
						}}/</span
					>
					{{ currentPlayer.userData.name }}
					<span class="color-primary">vs</span>
					{{ opponentPlayer.userData.name }}
				</div>
				<h1 class="page-heading__title h2">CS:GO MATCH</h1>
			</div>

			<div class="match-overview mt-sm-auto mb-sm-auto">
				<div class="match-overview__body">
					<figure class="match-team" role="group">
						<figure
							class="match-team-logo match-team-logo--team-1"
							role="group"
						>
							<img
								:src="
									currentPlayer.userData.steam.photos[1].value
								"
								:srcset="
									currentPlayer.userData.steam.photos[2].value
								"
								alt="Current Player Logo"
							/>
						</figure>
						<figcaption>
							<div class="match-team__name">
								{{ currentPlayer.name }}
							</div>
							<!-- <div class="match-team__country">
								{{ currentPlayer.team }}
							</div> -->
						</figcaption>
					</figure>
					<div class="col-md-6 col-lg-4">
						<div class="match-overview__result">
							<div class="match-result match-result--winner-left">
								<span
									class="match-result__score"
									style="font-size: 5.2rem"
								>
									{{
										currentPlayer.isWinner ? "Win" : "Lose"
									}}
									:
									{{
										opponentPlayer.isWinner ? "Win" : "Lose"
									}}
								</span>
								<span class="match-result__label"
									>Final score</span
								>
							</div>
						</div>
						<table class="match-overview__table">
							<thead>
								<tr>
									<th>Team</th>
									<th>Score</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Counter Terrorist</td>
									<td>
										<span
											:class="[
												match.scoreCT > match.scoreT
													? 'color-primary'
													: '',
											]"
											>{{ match.scoreCT }}</span
										>
									</td>
								</tr>
								<tr>
									<td>Terrorist</td>
									<td>
										<span
											:class="[
												match.scoreT > match.scoreCT
													? 'color-primary'
													: '',
											]"
											>{{ match.scoreT }}</span
										>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<figure class="match-team" role="group">
						<figure
							class="match-team-logo match-team-logo--team-5"
							role="group"
						>
							<img
								:src="
									opponentPlayer.userData.steam.photos[1]
										.value
								"
								:srcset="
									opponentPlayer.userData.steam.photos[2]
										.value
								"
								alt="Opponent Logo"
							/>
						</figure>
						<figcaption>
							<div class="match-team__name">
								{{ opponentPlayer.userData.name }}
							</div>
							<div class="match-team__country">
								{{ opponentPlayer.matchData.team }}
							</div>
						</figcaption>
					</figure>
				</div>
				<ul class="match-overview__footer list-unstyled">
					<li>
						<span>Started</span
						>{{
							`
							${new Date(match.start).getHours()}:${new Date(match.start).getMinutes()}
							${new Date(match.start).getDate()}/${
								new Date(match.start).getMonth() + 1
							}/${new Date(match.start).getFullYear()}
						`
						}}
					</li>
					<li>
						<span>Ended</span
						>{{
							`
							${new Date(match.end).getHours()}:${new Date(match.end).getMinutes()}
							${new Date(match.end).getDate()}/${
								new Date(match.end).getMonth() + 1
							}/${new Date(match.end).getFullYear()}
						`
						}}
					</li>
					<li>
						<span>Total Duration</span>
						{{
							Math.ceil(
								Math.abs(
									new Date(match.end) - new Date(match.start)
								) /
									(1000 * 60)
							)
						}}
						min
					</li>
					<li><span>Game</span>CS:GO</li>
					<li>
						<span>Bots</span
						>{{ match.players.filter((user) => user.isBot).length }}
					</li>
				</ul>
			</div>
		</div>
	</main>
</template>

<script>
export default {
	name: 'MatchResults',
	async created() {
		this.match = await this.$api.match.getMatch(this.$route.params.id);
	},
	data() {
		return {
			match: '',
		};
	},
	computed: {
		challenge() {
			const challenge = this.$store.getters.getChallengeById(
				this.$route.params.challengeId,
			);
			console.log(challenge);
			return challenge;
		},
		currentPlayer() {
			const player = this.challenge.players.find(
				(player) => player.id === this.$session.getUser().id,
			);
			const user = this.$store.getters.getUserById(player.id);
			return { ...player, user };
		},
		opponentPlayer() {
			const player = this.challenge.players.find(
				(player) => player.id !== this.$session.getUser().id,
			);
			const user = this.$store.getters.getUserById(player.id);
			return { ...player, user };
		},
	},
};
</script>

<style>
</style>
