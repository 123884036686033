<template>
  <main class="site-content site-content--center page" id="wrapper">
    <div class="container container--large">
			<div class="page-heading page-heading--default mb-5">
				<div class="page-heading__subtitle h3 color-primary mt-3">CHOOSE YOUR OPPONENT</div>
			</div>
			<form action="#" id="header-search-form" class="search-form search-form--header">
					<input type="text" v-model="searchValue" style="font-size:1.8rem" class="form-control" value="" placeholder="SEARCH FOR OPPONENTS">
			</form>
			<div class="table-responsive mt-sm-auto mb-sm-auto">
          <b-table
						:items="items"
						:fields="fields"
						:busy="isBusy"
						show-empty
						class="matches-table standings-table"
						:per-page="perPage"
						:current-page="currentPage">
						<template v-slot:cell(player)="data">
								<figure class="match-team" role="group">
									<figure class="match-team-logo match-team-logo--team-4" role="group">
										<img :src="data.item.photos.avatar" :srcset="data.item.photos.avatarBig" alt="User Avatar">
									</figure>
									<figcaption>
										<div class="match-team__name">{{ data.item.name }}</div>
									</figcaption>
							</figure>
						</template>
						<template v-slot:cell(id)="data">
							<b-button variant="primary" @click="sendChallenge(data)">
								<span>GET MATCHED</span>
							</b-button>
						</template>
						<template v-slot:empty>
							<h5 class="color-primary text-center my-auto">NO RECORDS FOUND</h5>
						</template>
          </b-table>
					<div class="mt-4">
						<b-pagination
							v-model="currentPage"
							:total-rows="rows"
							:per-page="perPage"
							size="lg"
							align="center"
						></b-pagination>
					</div>
			</div>
		</div>
  </main>
</template>

<script>
export default {
	name: 'Players',
	data() {
		return {
			fields: [
				{
					key: 'rank',
				},
				{
					key: 'player',
					tdClass: 'standings-table__team',
				},
				{
					key: 'favoriteGame',
					label: 'Favorite Game',
				},
				{
					key: 'win',
					tdClass: 'standings-table__wins',
				},
				{
					key: 'lose',
					tdClass: 'standings-table__losses',
				},
				{
					key: 'joinedOn',
					label: 'Joined On',
				},
				{
					key: 'id',
					label: '',
				},
			],
			isBusy: true,
			items: [],
			players: [],
			searchValue: '',
			currentPage: 1,
			perPage: 5,
		};
	},
	created() {
		this.getPlayers();
	},
	computed: {
		rows() {
			return this.items.length;
		},
	},
	methods: {
		async getPlayers() {
			try {
				this.players = await this.$api.players.getPlayers();
				const mapped = this.mapPlayers(this.players);
				this.items = mapped;
				this.isBusy = false;
			} catch (error) {
				console.log(error);
			}
		},
		mapPlayers(players) {
			const mapped = players.map((player) => ({
				id: player.id,
				rank: Math.floor(Math.random() * 10),
				name: player.name,
				photos: {
					avatar: player.steam ? player.steam.photos[1].value : '/img/unknown-avatar.jpg',
					avatarBig: player.steam ? player.steam.photos[2].value : '/img/unknown-avatar@2x.jpg',
				},
				favoriteGame: 'CS:GO',
				win: Math.floor(Math.random() * 100),
				lose: Math.floor(Math.random() * 100),
				joinedOn: new Date(player.createdOn).toDateString(),
			}));
			const userId = this.$session.getUser().id;
			return mapped.filter((player) => player.id !== userId);
		},
		async sendChallenge(data) {
			const invitedIds = [data.item.id];
			const challenge = await this.$store.dispatch('createChallenge', invitedIds);
			if (challenge) {
				this.$router.push({ name: 'MatchUp', params: { id: challenge.id } });
			} else {
				this.$toasted.error('An error occurred', {
					duration: 5000,
					position: 'top-right',
					className: 'notification',
				});
			}
		},
	},
	watch: {
		searchValue(newValue) {
			if (newValue) {
				const filtered = this.players.filter((item) => item.name.toLowerCase().trim().indexOf(newValue) !== -1);
				const mapped = this.mapPlayers(filtered);
				this.items = mapped;
			} else {
				this.items = this.mapPlayers(this.players);
			}
		},
	},
};
</script>

<style lang="scss" scoped>

	@media (min-width: 576px) {
		#header-search-form {
			margin-right: 2rem !important;
			margin-left: 2rem !important;
			margin-bottom: 0rem !important;
		}
	}

	@media (min-width: 768px) {
		#header-search-form {
			margin-right: 10rem !important;
			margin-left: 10rem !important;
			margin-bottom: 0rem !important;
		}
	}

	@media (min-width: 992px) {
		#header-search-form {
			margin-right: 20rem !important;
			margin-left: 20rem !important;
			margin-bottom: 0rem !important;
		}
	}

	@media (min-width: 1920px) {
		#header-search-form {
			margin-right: 30rem !important;
			margin-left:30rem !important;
			margin-bottom: 3.5rem !important;
		}
	}
</style>
