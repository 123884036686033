var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    {
      staticClass: "site-content site-content--center page",
      attrs: { id: "wrapper" }
    },
    [
      _vm.challenge && _vm.challenge.id
        ? _c("div", { staticClass: "container" }, [
            _c(
              "div",
              {
                staticClass:
                  "page-heading page-heading--default text-small text-center w-100"
              },
              [
                _c("div", { staticClass: "page-heading__subtitle h5" }, [
                  _c("span", { staticClass: "color-primary" }, [
                    _vm._v(
                      _vm._s(new Date(_vm.challenge.createdOn).toDateString()) +
                        " /"
                    )
                  ]),
                  _vm._v(" " + _vm._s(_vm.currentPlayer.name) + " "),
                  _c("span", { staticClass: "color-primary" }, [_vm._v("vs")]),
                  _vm._v(" " + _vm._s(_vm.opponentPlayer.name) + " ")
                ]),
                _c("h1", { staticClass: "page-heading__title h2" }, [
                  _vm._v("MATCHUP")
                ])
              ]
            ),
            _c("div", { staticClass: "matches-tabs__content tab-content" }, [
              _c(
                "div",
                {
                  staticClass:
                    "match-stats-container tab-pane fade show active",
                  attrs: { role: "tabpanel" }
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-4 my-auto" }, [
                      _c("ul", { staticClass: "match-stats-widget__item" }, [
                        _c("li", [
                          _c(
                            "figure",
                            {
                              staticClass: "match-player team-1",
                              attrs: { role: "group" }
                            },
                            [
                              _c(
                                "figure",
                                { staticClass: "match-player__avatar" },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.currentPlayer.avatar,
                                      alt: ""
                                    }
                                  })
                                ]
                              ),
                              _c("figcaption", [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "font-weight-bold ml-3 text-white"
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.currentPlayer.name) + " "
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        ]),
                        _vm._m(0),
                        !_vm.currentPlayer.acceptedOn &&
                        !_vm.currentPlayer.rejectedOn
                          ? _c(
                              "li",
                              [
                                !_vm.expired
                                  ? _c(
                                      "b-row",
                                      { staticClass: "justify-content-around" },
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { sm: "6" } },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "btn-outlined",
                                                attrs: { variant: "primary" },
                                                on: {
                                                  click: _vm.acceptChallenge
                                                }
                                              },
                                              [_c("span", [_vm._v("ACCEPT")])]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          { attrs: { sm: "6" } },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass:
                                                  "btn-outlined-danger",
                                                attrs: { variant: "primary" },
                                                on: {
                                                  click: _vm.declineChallenge
                                                }
                                              },
                                              [_c("span", [_vm._v("DECLINE")])]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _c(
                                      "p",
                                      { staticClass: "text-white mb-0" },
                                      [_vm._v("Did not join in time.")]
                                    )
                              ],
                              1
                            )
                          : _vm.currentPlayer.acceptedOn
                          ? _c("li", { staticClass: "text-white" }, [
                              !_vm.expired
                                ? _c("p", { staticClass: "text-white mb-0" }, [
                                    _vm._v("Ready!")
                                  ])
                                : _c("p", { staticClass: "text-white mb-0" }, [
                                    _vm._v("Joined")
                                  ])
                            ])
                          : _vm.currentPlayer.rejectedOn
                          ? _c("li", { staticClass: "text-white" }, [
                              _c("p", { staticClass: "text-white mb-0" }, [
                                _vm._v("Challenge Declined!")
                              ])
                            ])
                          : _vm._e()
                      ])
                    ]),
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "div",
                        {
                          staticClass: "card text-white",
                          staticStyle: { "background-color": "#13151d" }
                        },
                        [
                          _c("img", {
                            staticClass: "card-img-top",
                            attrs: {
                              src: require("@/assets/img/games/csgo.jpg"),
                              alt: "Card image cap"
                            }
                          }),
                          _c(
                            "div",
                            { staticClass: "card-body text-center" },
                            [
                              _c(
                                "h4",
                                { staticClass: "card-title text-white" },
                                [_vm._v("CS:GO - 1 VS 1")]
                              ),
                              _c("countdown", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.expired,
                                    expression: "!expired"
                                  }
                                ],
                                attrs: { time: _vm.remainingTime() },
                                on: { end: _vm.handleCountdownEnd },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(props) {
                                        return [
                                          _c(
                                            "h5",
                                            { staticClass: "text-white" },
                                            [
                                              _vm._v(
                                                _vm._s(props.minutes) +
                                                  " : " +
                                                  _vm._s(
                                                    _vm._f("padLeft")(
                                                      props.seconds
                                                    )
                                                  )
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2693352732
                                )
                              }),
                              _vm.currentPlayer.acceptedOn &&
                              _vm.opponentPlayer.acceptedOn &&
                              !_vm.challenge.startedOn &&
                              !_vm.expired
                                ? _c("h4", { staticClass: "text-white mt-4" }, [
                                    _vm._v(" Challenge accepted! ")
                                  ])
                                : _vm._e(),
                              _vm.challenge.startedOn &&
                              !_vm.challenge.finishedOn
                                ? _c("h4", { staticClass: "text-white mt-4" }, [
                                    _vm._v(" Challenge in progress! ")
                                  ])
                                : _vm._e(),
                              !_vm.challenge.startedOn && _vm.expired
                                ? _c(
                                    "h4",
                                    { staticClass: "text-danger mt-4" },
                                    [_vm._v(" Challenge expired! ")]
                                  )
                                : _vm._e(),
                              _vm.currentPlayer.rejectedOn ||
                              _vm.opponentPlayer.rejectedOn
                                ? _c(
                                    "h4",
                                    { staticClass: "text-danger mt-4" },
                                    [_vm._v(" Challenge Aborted! ")]
                                  )
                                : _vm._e(),
                              _vm.challenge.finishedOn
                                ? _c("h4", { staticClass: "text-white mt-4" }, [
                                    _vm._v(" Challenge finished! ")
                                  ])
                                : _vm._e(),
                              !_vm.expired && !_vm.challenge.startedOn
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "btn-block mb-3 mt-4",
                                      attrs: {
                                        tag: "a",
                                        variant: "primary",
                                        disabled: !_vm.enableJoining,
                                        rel: "noreferrer noopener",
                                        target: "_blank",
                                        href:
                                          "steam://connect/85.215.84.8:27025"
                                      }
                                    },
                                    [_c("span", [_vm._v("JOIN NOW")])]
                                  )
                                : _vm.challenge.gameMatchId
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "btn-block mb-3 mt-4",
                                      attrs: {
                                        tag: "a",
                                        variant: "primary",
                                        to: {
                                          name: "MatchResult",
                                          params: {
                                            challengeId: _vm.challenge.id,
                                            id: _vm.challenge.gameMatchId
                                          }
                                        }
                                      }
                                    },
                                    [_c("span", [_vm._v("VIEW DETAILS")])]
                                  )
                                : _vm._e(),
                              !_vm.expired && !_vm.challenge.startedOn
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "btn-outlined btn-block",
                                      attrs: { variant: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.copyServerIp()
                                        }
                                      }
                                    },
                                    [_c("span", [_vm._v("COPY SERVER IP")])]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          !_vm.expired
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "card-footer text-muted text-center font-weight-bold",
                                  staticStyle: { "font-size": "1rem" }
                                },
                                [
                                  _vm._v(
                                    ' If the "JOIN NOW" button doesn\'t send you directly to the server, you can join the server with your console "connect Server" '
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "col-sm-4 my-auto" }, [
                      _c("ul", { staticClass: "match-stats-widget__item" }, [
                        _c("li", [
                          _c(
                            "figure",
                            {
                              staticClass: "match-player team-2",
                              attrs: { role: "group" }
                            },
                            [
                              _c(
                                "figure",
                                { staticClass: "match-player__avatar" },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.opponentPlayer.avatar,
                                      alt: ""
                                    }
                                  })
                                ]
                              ),
                              _c("figcaption", [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "ml-3 text-white font-weight-bold"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.opponentPlayer.name) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        ]),
                        _vm._m(1),
                        _vm.opponentPlayer.acceptedOn
                          ? _c("li", [
                              !_vm.expired
                                ? _c("p", { staticClass: "text-white mb-0" }, [
                                    _vm._v("Ready!")
                                  ])
                                : _c("p", { staticClass: "text-white mb-0" }, [
                                    _vm._v("Joined.")
                                  ])
                            ])
                          : _vm.opponentPlayer.rejectedOn
                          ? _c("li", [
                              _c("p", { staticClass: "text-white mb-0" }, [
                                _vm._v("Challenge Declined!")
                              ])
                            ])
                          : !_vm.expired
                          ? _c("li", [
                              _c("p", { staticClass: "text-white mb-0" }, [
                                _vm._v("Waiting for player to join...")
                              ])
                            ])
                          : _c("li", [
                              _c("p", { staticClass: "text-white mb-0" }, [
                                _vm._v("Did not join in time.")
                              ])
                            ])
                      ])
                    ])
                  ])
                ]
              )
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", { staticClass: "col-sm-4 text-white font-weight-bold" }, [
        _vm._v("WIN / LOSE")
      ]),
      _c(
        "span",
        { staticClass: "col-sm-3  offset-sm-4 text-white font-weight-bold" },
        [_vm._v("48 / 13")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", { staticClass: "col-sm-4 text-white font-weight-bold" }, [
        _vm._v("WIN / LOSE")
      ]),
      _c(
        "span",
        { staticClass: "col-sm-3  offset-sm-4 text-white font-weight-bold" },
        [_vm._v("84 / 21")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }