<template>
  <main class="site-content site-content--center page" id="wrapper">
			<div class="container" v-if="challenge && challenge.id">
				<div class="page-heading page-heading--default text-small text-center w-100">
					<div class="page-heading__subtitle h5">
						<span class="color-primary">{{ new Date(challenge.createdOn).toDateString() }} /</span>
						{{ currentPlayer.name }}
						<span class="color-primary">vs</span>
						{{ opponentPlayer.name }}
					</div>
					<h1 class="page-heading__title h2">MATCHUP</h1>
				</div>
				<div class="matches-tabs__content tab-content">
					<div class="match-stats-container tab-pane fade show active" role="tabpanel">
							<div class="row">
								<div class="col-sm-4 my-auto">
									<ul class="match-stats-widget__item">
												<li>
													<figure class="match-player team-1" role="group">
														<figure class="match-player__avatar">
																<img :src="currentPlayer.avatar" alt="">
														</figure>
														<figcaption>
															<span class="font-weight-bold ml-3 text-white">
																{{ currentPlayer.name }}
															</span>
														</figcaption>
													</figure>
												</li>
												<li>
													<span class="col-sm-4 text-white font-weight-bold">WIN / LOSE</span>
													<span class="col-sm-3  offset-sm-4 text-white font-weight-bold">48 / 13</span>
												</li>
												<li v-if="!currentPlayer.acceptedOn && !currentPlayer.rejectedOn">
													<b-row class="justify-content-around" v-if="!expired">
														<b-col sm=6>
															<b-button variant="primary" class="btn-outlined" @click="acceptChallenge">
																<span>ACCEPT</span>
															</b-button>
														</b-col>
														<b-col sm=6>
															<b-button variant="primary" class="btn-outlined-danger" @click="declineChallenge">
																<span>DECLINE</span>
															</b-button>
														</b-col>
													</b-row>
													<p class="text-white mb-0" v-else>Did not join in time.</p>
												</li>
												<li class="text-white" v-else-if="currentPlayer.acceptedOn">
													<p v-if="!expired" class="text-white mb-0">Ready!</p>
													<p v-else class="text-white mb-0">Joined</p>
												</li>
												<li class="text-white" v-else-if="currentPlayer.rejectedOn">
													<p class="text-white mb-0">Challenge Declined!</p>
												</li>
											</ul>
								</div>
								<div class="col-sm-4">
									<div class="card text-white" style="background-color: #13151d">
										<img class="card-img-top" src="@/assets/img/games/csgo.jpg" alt="Card image cap">
										<div class="card-body text-center">
											<h4 class="card-title text-white">CS:GO - 1 VS 1</h4>
											<countdown :time="remainingTime()" @end="handleCountdownEnd" v-show="!expired">
												<template slot-scope="props">
													<h5 class="text-white">{{ props.minutes }} : {{ props.seconds | padLeft }}</h5>
												</template>
											</countdown>
											<!-- Check if challenge is expired  -->
											<h4
												v-if="currentPlayer.acceptedOn && opponentPlayer.acceptedOn && !challenge.startedOn && !expired"
												class="text-white mt-4">
													Challenge accepted!
											</h4>
											<h4
												v-if="challenge.startedOn && !challenge.finishedOn"
												class="text-white mt-4">
													Challenge in progress!
											</h4>
											<h4
												v-if="!challenge.startedOn && expired"
												class="text-danger mt-4">
													Challenge expired!
											</h4>
											<h4
												v-if="currentPlayer.rejectedOn || opponentPlayer.rejectedOn"
												class="text-danger mt-4">
													Challenge Aborted!
											</h4>
											<h4
												v-if="challenge.finishedOn"
												class="text-white mt-4">
												Challenge finished!
											</h4>
											<b-button
												v-if="!expired && !challenge.startedOn"
												tag="a"
												variant="primary"
												:disabled="!enableJoining"
												class="btn-block mb-3 mt-4"
												rel="noreferrer noopener"
												target="_blank"
												href="steam://connect/85.215.84.8:27025">
												<!-- ISSUE IP adress should come from backend -->
												<span>JOIN NOW</span>
											</b-button>
											<b-button
												v-else-if="challenge.gameMatchId"
												tag="a"
												variant="primary"
												class="btn-block mb-3 mt-4"
												:to="{ name: 'MatchResult', params: { challengeId: challenge.id, id: challenge.gameMatchId } }">
												<span>VIEW DETAILS</span>
											</b-button>
											<b-button variant="primary" class="btn-outlined btn-block" @click=copyServerIp() v-if="!expired && !challenge.startedOn">
												<span>COPY SERVER IP</span>
											</b-button>
										</div>
										<div class="card-footer text-muted text-center font-weight-bold" style="font-size: 1rem" v-if="!expired">
											If the "JOIN NOW" button doesn't send you directly to the server, you can join the server with your console "connect Server"
										</div>
									</div>
								</div>
								<div class="col-sm-4 my-auto">
									<ul class="match-stats-widget__item">
												<li>
													<figure class="match-player team-2" role="group">
														<figure class="match-player__avatar">
																<img :src="opponentPlayer.avatar" alt="">
														</figure>
														<figcaption>
															<span class="ml-3 text-white font-weight-bold">
																{{ opponentPlayer.name }}
															</span>
														</figcaption>
													</figure>
												</li>
												<li>
													<span class="col-sm-4 text-white font-weight-bold">WIN / LOSE</span>
													<span class="col-sm-3  offset-sm-4 text-white font-weight-bold">84 / 21</span>
												</li>
												<li v-if="opponentPlayer.acceptedOn">
													<p v-if="!expired" class="text-white mb-0">Ready!</p>
													<p v-else class="text-white mb-0">Joined.</p>
												</li>
												<li v-else-if="opponentPlayer.rejectedOn">
													<p class="text-white mb-0">Challenge Declined!</p>
												</li>
												<li v-else-if="!expired">
													<p class="text-white mb-0">Waiting for player to join...</p>
												</li>
												<li v-else>
													<p class="text-white mb-0">Did not join in time.</p>
												</li>
											</ul>
								</div>
							</div>
					</div>
				</div>
			</div>
		</main>
</template>

<script>
export default {
	name: 'Matchup',
	data() {
		return {
			expired: true,
		};
	},
	filters: {
		padLeft: (value, max = 2, filler = '0') => `${value}`.padStart(max, filler),
	},
	methods: {
		async acceptChallenge() {
			await this.$store.dispatch('acceptChallenge', this.challenge.id);
		},
		async declineChallenge() {
			await this.$store.dispatch('declineChallenge', this.challenge.id);
		},
		async copyServerIp() {
			try {
				await navigator.clipboard.writeText('85.215.84.8:27015');
				this.$toasted.info('Copied to clipboad', {
					duration: 3000,
					position: 'top-right',
					className: 'notification',
				});
			} catch (error) {
				this.$toasted.error('Could not copy', {
					duration: 3000,
					position: 'top-right',
					className: 'notification',
				});
			}
		},
		handleCountdownEnd() {
			this.expired = true;
		},
		remainingTime() {
			const remainingTime = new Date(this.challenge.createdOn).getTime() - new Date().getTime();
			if (Math.abs(remainingTime) < 120000) {
				this.expired = false;
				return remainingTime + 120000;
			}
			return 0;
		},
	},
	computed: {
		challenge() {
			return this.$store.getters.getChallengeById(this.$route.params.id);
		},
		currentPlayer() {
			return this.challenge.players.find((player) => player.id === this.$session.getUser().id);
		},
		opponentPlayer() {
			return this.challenge.players.find((player) => player.id !== this.$session.getUser().id);
		},
		enableJoining() {
			return this.currentPlayer.acceptedOn && this.opponentPlayer.acceptedOn && !this.challenge.startedOn && !this.challenge.finishedOn;
		},
	},
};
</script>

<style scoped>
</style>
