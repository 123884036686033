var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    {
      staticClass: "site-content site-content--center page",
      attrs: { id: "wrapper" }
    },
    [
      _c("div", { staticClass: "container container--large" }, [
        _vm._m(0),
        _c(
          "form",
          {
            staticClass: "search-form search-form--header",
            attrs: { action: "#", id: "header-search-form" }
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchValue,
                  expression: "searchValue"
                }
              ],
              staticClass: "form-control",
              staticStyle: { "font-size": "1.8rem" },
              attrs: {
                type: "text",
                value: "",
                placeholder: "SEARCH FOR OPPONENTS"
              },
              domProps: { value: _vm.searchValue },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchValue = $event.target.value
                }
              }
            })
          ]
        ),
        _c(
          "div",
          { staticClass: "table-responsive mt-sm-auto mb-sm-auto" },
          [
            _c("b-table", {
              staticClass: "matches-table standings-table",
              attrs: {
                items: _vm.items,
                fields: _vm.fields,
                busy: _vm.isBusy,
                "show-empty": "",
                "per-page": _vm.perPage,
                "current-page": _vm.currentPage
              },
              scopedSlots: _vm._u([
                {
                  key: "cell(player)",
                  fn: function(data) {
                    return [
                      _c(
                        "figure",
                        { staticClass: "match-team", attrs: { role: "group" } },
                        [
                          _c(
                            "figure",
                            {
                              staticClass:
                                "match-team-logo match-team-logo--team-4",
                              attrs: { role: "group" }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: data.item.photos.avatar,
                                  srcset: data.item.photos.avatarBig,
                                  alt: "User Avatar"
                                }
                              })
                            ]
                          ),
                          _c("figcaption", [
                            _c("div", { staticClass: "match-team__name" }, [
                              _vm._v(_vm._s(data.item.name))
                            ])
                          ])
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "cell(id)",
                  fn: function(data) {
                    return [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.sendChallenge(data)
                            }
                          }
                        },
                        [_c("span", [_vm._v("GET MATCHED")])]
                      )
                    ]
                  }
                },
                {
                  key: "empty",
                  fn: function() {
                    return [
                      _c(
                        "h5",
                        { staticClass: "color-primary text-center my-auto" },
                        [_vm._v("NO RECORDS FOUND")]
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            }),
            _c(
              "div",
              { staticClass: "mt-4" },
              [
                _c("b-pagination", {
                  attrs: {
                    "total-rows": _vm.rows,
                    "per-page": _vm.perPage,
                    size: "lg",
                    align: "center"
                  },
                  model: {
                    value: _vm.currentPage,
                    callback: function($$v) {
                      _vm.currentPage = $$v
                    },
                    expression: "currentPage"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "page-heading page-heading--default mb-5" },
      [
        _c(
          "div",
          { staticClass: "page-heading__subtitle h3 color-primary mt-3" },
          [_vm._v("CHOOSE YOUR OPPONENT")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }