var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.challenge !== undefined
    ? _c(
        "main",
        {
          staticClass: "site-content site-content--center page",
          attrs: { id: "wrapper" }
        },
        [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              {
                staticClass:
                  "page-heading page-heading--default text-small text-center w-100"
              },
              [
                _c("div", { staticClass: "page-heading__subtitle h5" }, [
                  _c("span", { staticClass: "color-primary" }, [
                    _vm._v(
                      _vm._s(new Date(_vm.challenge.createdOn).toDateString()) +
                        "/"
                    )
                  ]),
                  _vm._v(" " + _vm._s(_vm.currentPlayer.userData.name) + " "),
                  _c("span", { staticClass: "color-primary" }, [_vm._v("vs")]),
                  _vm._v(" " + _vm._s(_vm.opponentPlayer.userData.name) + " ")
                ]),
                _c("h1", { staticClass: "page-heading__title h2" }, [
                  _vm._v("CS:GO MATCH")
                ])
              ]
            ),
            _c("div", { staticClass: "match-overview mt-sm-auto mb-sm-auto" }, [
              _c("div", { staticClass: "match-overview__body" }, [
                _c(
                  "figure",
                  { staticClass: "match-team", attrs: { role: "group" } },
                  [
                    _c(
                      "figure",
                      {
                        staticClass: "match-team-logo match-team-logo--team-1",
                        attrs: { role: "group" }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src:
                              _vm.currentPlayer.userData.steam.photos[1].value,
                            srcset:
                              _vm.currentPlayer.userData.steam.photos[2].value,
                            alt: "Current Player Logo"
                          }
                        })
                      ]
                    ),
                    _c("figcaption", [
                      _c("div", { staticClass: "match-team__name" }, [
                        _vm._v(" " + _vm._s(_vm.currentPlayer.name) + " ")
                      ])
                    ])
                  ]
                ),
                _c("div", { staticClass: "col-md-6 col-lg-4" }, [
                  _c("div", { staticClass: "match-overview__result" }, [
                    _c(
                      "div",
                      { staticClass: "match-result match-result--winner-left" },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "match-result__score",
                            staticStyle: { "font-size": "5.2rem" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.currentPlayer.isWinner ? "Win" : "Lose"
                                ) +
                                " : " +
                                _vm._s(
                                  _vm.opponentPlayer.isWinner ? "Win" : "Lose"
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c("span", { staticClass: "match-result__label" }, [
                          _vm._v("Final score")
                        ])
                      ]
                    )
                  ]),
                  _c("table", { staticClass: "match-overview__table" }, [
                    _vm._m(0),
                    _c("tbody", [
                      _c("tr", [
                        _c("td", [_vm._v("Counter Terrorist")]),
                        _c("td", [
                          _c(
                            "span",
                            {
                              class: [
                                _vm.match.scoreCT > _vm.match.scoreT
                                  ? "color-primary"
                                  : ""
                              ]
                            },
                            [_vm._v(_vm._s(_vm.match.scoreCT))]
                          )
                        ])
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("Terrorist")]),
                        _c("td", [
                          _c(
                            "span",
                            {
                              class: [
                                _vm.match.scoreT > _vm.match.scoreCT
                                  ? "color-primary"
                                  : ""
                              ]
                            },
                            [_vm._v(_vm._s(_vm.match.scoreT))]
                          )
                        ])
                      ])
                    ])
                  ])
                ]),
                _c(
                  "figure",
                  { staticClass: "match-team", attrs: { role: "group" } },
                  [
                    _c(
                      "figure",
                      {
                        staticClass: "match-team-logo match-team-logo--team-5",
                        attrs: { role: "group" }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src:
                              _vm.opponentPlayer.userData.steam.photos[1].value,
                            srcset:
                              _vm.opponentPlayer.userData.steam.photos[2].value,
                            alt: "Opponent Logo"
                          }
                        })
                      ]
                    ),
                    _c("figcaption", [
                      _c("div", { staticClass: "match-team__name" }, [
                        _vm._v(
                          " " + _vm._s(_vm.opponentPlayer.userData.name) + " "
                        )
                      ]),
                      _c("div", { staticClass: "match-team__country" }, [
                        _vm._v(
                          " " + _vm._s(_vm.opponentPlayer.matchData.team) + " "
                        )
                      ])
                    ])
                  ]
                )
              ]),
              _c(
                "ul",
                { staticClass: "match-overview__footer list-unstyled" },
                [
                  _c("li", [
                    _c("span", [_vm._v("Started")]),
                    _vm._v(
                      _vm._s(
                        " " +
                          new Date(_vm.match.start).getHours() +
                          ":" +
                          new Date(_vm.match.start).getMinutes() +
                          " " +
                          new Date(_vm.match.start).getDate() +
                          "/" +
                          (new Date(_vm.match.start).getMonth() + 1) +
                          "/" +
                          new Date(_vm.match.start).getFullYear() +
                          " "
                      ) + " "
                    )
                  ]),
                  _c("li", [
                    _c("span", [_vm._v("Ended")]),
                    _vm._v(
                      _vm._s(
                        " " +
                          new Date(_vm.match.end).getHours() +
                          ":" +
                          new Date(_vm.match.end).getMinutes() +
                          " " +
                          new Date(_vm.match.end).getDate() +
                          "/" +
                          (new Date(_vm.match.end).getMonth() + 1) +
                          "/" +
                          new Date(_vm.match.end).getFullYear() +
                          " "
                      ) + " "
                    )
                  ]),
                  _c("li", [
                    _c("span", [_vm._v("Total Duration")]),
                    _vm._v(
                      " " +
                        _vm._s(
                          Math.ceil(
                            Math.abs(
                              new Date(_vm.match.end) -
                                new Date(_vm.match.start)
                            ) /
                              (1000 * 60)
                          )
                        ) +
                        " min "
                    )
                  ]),
                  _vm._m(1),
                  _c("li", [
                    _c("span", [_vm._v("Bots")]),
                    _vm._v(
                      _vm._s(
                        _vm.match.players.filter(function(user) {
                          return user.isBot
                        }).length
                      ) + " "
                    )
                  ])
                ]
              )
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [_c("th", [_vm._v("Team")]), _c("th", [_vm._v("Score")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("span", [_vm._v("Game")]), _vm._v("CS:GO")])
  }
]
render._withStripped = true

export { render, staticRenderFns }